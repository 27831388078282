import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DatePicker, InputNumber, message, Radio, Space } from 'antd'
import dayjs from 'dayjs'
import { loadStripe } from '@stripe/stripe-js'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import { Form, Button, Text, Input, Title, Column } from 'styles/global'
import { JobOffer } from 'types'
import { jobOfferService } from 'services'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { CountrysSelect, DepartmentsSelect, RegionSelect, SalaryInput } from 'components'
import { UserContext } from 'providers'
import { routes } from 'router'
import { IS_PUBLISH_OFFER_FREE } from 'constants/index'
import CitySelect from 'components/GlobalComponents/citySelect'
import { theme } from 'styles'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '')
let stripe
stripePromise.then((resStripe) => (stripe = resStripe))

function padTo2Digits(num) {
  return num.toString().padStart(2, '0')
}

function formatDate(date) {
  return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join(
    '/',
  )
}

const CreateEditJobOffer = ({
  setIsLoading,
  defaultJobOffer,
}: {
  setIsLoading?: Dispatch<SetStateAction<boolean>>
  defaultJobOffer?: JobOffer
}) => {
  const [jobOffer, setJobOffer] = useState<JobOffer>(
    defaultJobOffer
      ? defaultJobOffer
      : {
          officeName: '',
          title: '',
          jobType: '',
          contractType: '',
          description: '',
          editorialAssistance: false,
          salary: {
            from: 0,
            to: undefined,
            frequency: 'PER_MONTH',
            currency: 'EUR',
            isNotSpecified: false,
          },
          workingHours: '',
          startDate: formatDate(new Date()),
          address: {
            street: '',
            city: '',
            postalCode: '',
            country: '',
            region: '',
          },
          contact: {
            email: '',
            phone: '',
          },
        },
  )
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty())
  const [isDefaultJobOfferLoading, setIsDefaultJobOfferLoeading] = useState<boolean>(false)
  const { user } = useContext(UserContext)
  const navigate = useNavigate()

  const editor = React.useRef(null)

  const addDefaultCompanyInformations = () => {
    const company = user.companyInformations
    if (company) {
      setJobOffer({
        ...jobOffer,
        officeName: company.name,
        address: { region: '', ...company.address },
        contact: { email: company.email, phone: company.phone },
      })
      setIsDefaultJobOfferLoeading(!isDefaultJobOfferLoading)
    }
  }

  useEffect(() => {
    if (!defaultJobOffer && user._id) {
      addDefaultCompanyInformations()
    }
  }, [user])

  useEffect(() => {
    if (defaultJobOffer) {
      setJobOffer(defaultJobOffer)
      setIsDefaultJobOfferLoeading(!isDefaultJobOfferLoading)
      if (defaultJobOffer.description) {
        setEditorState(
          EditorState.createWithContent(convertFromRaw(JSON.parse(defaultJobOffer.description))),
        )
      }
    }
  }, [defaultJobOffer])

  const onObjectChange = (attr1: string, attr2: string, value: string) => {
    setJobOffer({ ...jobOffer, [attr1]: { ...jobOffer[attr1], [attr2]: value } })
  }

  const handleChange = (attr, value) => {
    setJobOffer({ ...jobOffer, [attr]: value })
  }

  const onFinish = (values: any) => {
    if (setIsLoading) {
      setIsLoading(true)
    }
    const describeContent = editorState.getCurrentContent()
    const description = JSON.stringify(convertToRaw(describeContent))

    const idEdit = !!defaultJobOffer
    if (idEdit) {
      jobOfferService
        .update({ ...jobOffer, description }, { id: defaultJobOffer._id })
        .then((res) => {
          if (res.status === 200) {
            message.success(`Offre d'emploi correctement mise à jour`)
            navigate(routes.myOffers.path)
          } else {
            message.error('Une erreur est servenue, veuillez nous contacter')
          }
        })
    } else {
      jobOfferService
        .create({ ...jobOffer, description }, { userId: user._id })
        .then(({ sessionId }) => {
          if (IS_PUBLISH_OFFER_FREE === true) {
            message.success(`Offre d'emploi correctement mise à jour`)
            navigate(routes.myOffers.path)
          } else if (sessionId) {
            stripe.redirectToCheckout({ sessionId })
          } else {
            if (setIsLoading) {
              setIsLoading(false)
            }
            message.error('Une erreur est servenue, veuillez nous contacter')
          }
        })
    }
  }

  return (
    <Form
      scrollToFirstError
      responsive="width: 95vw; max-width: 450px;"
      width="450px"
      layout="vertical"
      onFinish={onFinish}
      isStyled
      key={isDefaultJobOfferLoading}
    >
      <Title margin="0 0 30px 0" color="secondary">
        Déposer une offre d’emploi
      </Title>
      <Form.Item
        name="officeName"
        onChange={(e) => handleChange('officeName', e.target.value)}
        label="Nom de votre entreprise"
        rules={[
          {
            required: !jobOffer.officeName,
            message: 'Veuillez renseigner votre nom de entreprise',
          },
        ]}
      >
        <Input defaultValue={jobOffer.officeName} width="100%" />
      </Form.Item>
      <Form.Item
        name="title"
        onChange={(e) => handleChange('title', e.target.value)}
        label="Titre de l'annonce"
        rules={[
          {
            required: !jobOffer.title,
            message: "Veuillez renseigner le titre de l'annonce",
          },
        ]}
      >
        <Input defaultValue={jobOffer.title} width="100%" />
      </Form.Item>
      <Text margin="18px 0 10px 0" size="16px">
        Adresse
      </Text>

      <Form.Item
        name="Pays"
        label="Pays"
        rules={[
          { required: !jobOffer.address?.country, message: 'Veuillez renseigner votre pays' },
        ]}
      >
        <CountrysSelect
          defaultValue={jobOffer.address.country}
          setSelectedCountry={(value) => onObjectChange('address', 'country', value)}
        />
      </Form.Item>
      <Form.Item
        name="Région"
        label="Région"
        rules={[{ required: false, message: 'Veuillez renseigner votre région' }]}
      >
        <RegionSelect
          defaultValue={jobOffer.address.region}
          setSelectedRegion={(value) => onObjectChange('address', 'region', value)}
        />
      </Form.Item>
      <Form.Item
        name="street"
        onChange={(e) => onObjectChange('address', 'street', e.target.value)}
        label="Rue et numéro de rue"
        rules={[
          {
            required: false,
            message: 'Veuillez renseigner votre rue et numéro de rue',
          },
        ]}
      >
        <Input defaultValue={jobOffer.address?.street} width="100%" />
      </Form.Item>
      <Form.Item
        name="city"
        onChange={(e) => onObjectChange('address', 'city', e.target.value)}
        label="Ville"
        rules={[{ required: !jobOffer.address?.city, message: 'Veuillez renseigner votre ville' }]}
      >
        <CitySelect
          setSelectedCity={(value) => onObjectChange('address', 'city', value)}
          isMultiple={false}
          defaultValue={jobOffer.address?.city}
        />
        {/* <Input defaultValue={jobOffer.address?.city} width="100%" />*/}
      </Form.Item>
      <Form.Item
        name="postalCode"
        label="Département"
        rules={[
          {
            required: !jobOffer.address?.postalCode,
            message: 'Veuillez renseigner votre département',
          },
        ]}
      >
        <DepartmentsSelect
          setSelectedDepartments={(value) => onObjectChange('address', 'postalCode', value)}
          isMultiple={false}
          defaultValue={jobOffer.address?.postalCode}
        />
      </Form.Item>
      <Text margin="18px 0 10px 0" size="16px">
        Contact
      </Text>
      <Form.Item
        name="email"
        onChange={(e) => onObjectChange('contact', 'email', e.target.value)}
        label="Adresse e-mail"
        rules={[
          {
            required: !jobOffer.contact?.email,
            message: 'Veuillez renseigner votre adresse email',
          },
        ]}
      >
        <Input defaultValue={jobOffer.contact?.email} width="100%" />
      </Form.Item>
      <Form.Item
        name="phone"
        onChange={(e) => onObjectChange('contact', 'phone', e.target.value)}
        label="Téléphone"
        rules={[
          {
            required: !jobOffer.contact?.phone,
            message: 'Veuillez renseigner votre numéro de téléphone',
          },
        ]}
      >
        <Input defaultValue={jobOffer.contact?.phone} width="100%" />
      </Form.Item>
      <Form.Item
        name="description"
        onChange={(e) => handleChange('description', e.target.value)}
        label="Description du poste"
        rules={[{ required: !editorState, message: 'Veuillez renseigner la description du poste' }]}
      >
        <Editor
          ref={editor}
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={(editorState) => setEditorState(editorState)}
          toolbar={{
            options: [
              'inline',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'emoji',
              'image',
              'remove',
              'history',
            ],
          }}
        />
      </Form.Item>
      <Checkbox
        checked={jobOffer.editorialAssistance}
        style={{ marginBottom: '30px', color: theme.color.greyBlack }}
        onChange={() => handleChange('editorialAssistance', !jobOffer.editorialAssistance)}
      >
        Accepter qu'un expert en recrutement et en marketing améliore le texte de mon offre d'emploi
      </Checkbox>
      <Form.Item
        name="jobType"
        onChange={(e) => handleChange('jobType', e.target.value)}
        label="Type de poste"
        rules={[
          {
            required: !defaultJobOffer?.jobType,
            message: 'Veuillez renseigner le type de contrat',
          },
        ]}
      >
        <Radio.Group defaultValue={defaultJobOffer?.jobType}>
          <Space direction="vertical">
            <Radio value={'DEA'}>DEA (ambulancier diplômé d’état)</Radio>
            <Radio value={'AA'}>AA (auxiliaire ambulancier)</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="contractType"
        onChange={(e) => handleChange('contractType', e.target.value)}
        label="Type de contrat"
        rules={[
          {
            required: !defaultJobOffer?.contractType,
            message: 'Veuillez renseigner le type de contrat',
          },
        ]}
      >
        <Radio.Group defaultValue={defaultJobOffer?.contractType}>
          <Space direction="vertical">
            <Radio value={'CDI'}>CDI</Radio>
            <Radio value={'CDD'}>CDD</Radio>
            <Radio value={'Remplacement'}>Remplacement</Radio>
            <Radio value={'Interim'}>Interim</Radio>
            <Radio value={'Gérance'}>Gérance</Radio>
            <Radio value={'Garde'}>Garde</Radio>
            <Radio value={'Contrat d’apprentissage'}>Contrat d’apprentissage</Radio>
            <Radio value={'Stage'}>Stage</Radio>
            <Radio value={'Non spécifié'}>Non spécifié</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <SalaryInput
        salary={jobOffer.salary}
        setSalary={(value) => setJobOffer({ ...jobOffer, salary: value })}
      />

      <Form.Item
        name="workingHours"
        onChange={(e) => handleChange('workingHours', e.target.value)}
        label="Temps de travail"
        rules={[
          {
            required: !jobOffer?.workingHours,
            message: 'Veuillez renseigner le temps de travail',
          },
        ]}
        style={{ marginTop: '30px' }}
      >
        <Input
          style={{ width: '100%', marginRight: '10px' }}
          //@ts-ignore
          defaultValue={defaultJobOffer?.workingHours}
          maxLength={50}
        />
      </Form.Item>
      <Form.Item
        name="startDate"
        label="Date de début souhaité"
        rules={[
          {
            required: !jobOffer.startDate,
            message: 'Veuillez renseigner une date de début',
          },
        ]}
        className="column"
        style={{ marginTop: '30px' }}
      >
        <DatePicker
          defaultValue={
            defaultJobOffer?.startDate && defaultJobOffer?.startDate !== 'Dès que possible'
              ? dayjs(defaultJobOffer?.startDate, 'DD/MM/YYYY')
              : dayjs(new Date())
          }
          format={'DD/MM/YYYY'}
          style={{ width: '180px' }}
          placeholder="Selectionner date"
          onChange={(date, dateString) => handleChange('startDate', dateString)}
        />
        <Checkbox
          checked={jobOffer.startDate === 'Dès que possible'}
          style={{ marginTop: '7px' }}
          onChange={() =>
            handleChange(
              'startDate',
              jobOffer.startDate === 'Dès que possible'
                ? formatDate(new Date())
                : 'Dès que possible',
            )
          }
        >
          Dès que possible
        </Checkbox>
      </Form.Item>
      {defaultJobOffer ? (
        <Form.Item>
          <Button.Primary width="100%" type="primary" htmlType="submit">
            Éditer mon offre
          </Button.Primary>
        </Form.Item>
      ) : (
        <>
          <Text margin="3px 0 13px 0" size="14px">
            L'annonce sera mise en avant pendant 1 mois
          </Text>
          <Form.Item>
            <Button.Primary width="100%" type="primary" htmlType="submit">
              {IS_PUBLISH_OFFER_FREE !== true ? 'Payer et publier mon offre' : 'Publier mon offre'}
            </Button.Primary>
          </Form.Item>
        </>
      )}
    </Form>
  )
}

export default CreateEditJobOffer
