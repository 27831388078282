import React from "react";

import GetStarted from "../getStarted/GetStarted";
import coverImg from "assets/blog/global/cover.svg";

const BlogHeader = () => {

  return (
    <div style={{ marginBottom: "50px" }} className="row-wrap-blog width-100">
      <div className="column header-container">
        <h1 className="title-bold montserrat start" style={{ fontWeight: "800", fontSize: "28px" }}>
        Entreprise d'ambulance ? <br />
        Ambulancier ?
        </h1>
        <h2 style={{ marginBottom: "15px", fontSize: "14px" }} className="text-300 grey">
        Trouvez tout ce dont vous avez besoin en terme d'emploi ambulancier à portée de clic.
        </h2>
        <GetStarted />
      </div>
      <img alt="01supply blog" src={coverImg} className="cover" />
    </div>
  );
};

export default BlogHeader;
