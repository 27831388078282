/* eslint-disable */
// @ts-nocheck

import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { message, Checkbox, InputNumber, Radio } from 'antd'
import { loadStripe } from '@stripe/stripe-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import { Form, Button, Text, Input, Title, Row, Column } from 'styles/global'
import { IsPublishContainer } from './style'
import { Candidate } from 'types'
import { userService } from 'services'
import {
  CountrysSelect,
  RegionSelect,
  DepartmentsSelect,
  UploadImage,
  NavigationBlocker,
  SalaryInput
} from 'components'
import { UserContext } from 'providers/UserProvider'
import { routes } from 'router'
import { useCallbackPrompt } from 'hooks'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '')
let stripe
stripePromise.then((resStripe) => (stripe = resStripe))

type File = {
  base64: string
  name: string
}

const InfromationsForm = ({
  setIsLoading,
}: {
  setIsLoading?: Dispatch<SetStateAction<boolean>>
}) => {
  const [candidateInformations, setCandidateInformations] = useState<Candidate>({
    name: '',
    email: '',
    localisations: {
      countrys: [],
      regions: [],
      departments: [],
    },
    experienceYears: '',
    school: '',
    phone: '',
    profilePicture: '',
    cv: '',
    desiredSalary: {
      from: 0,
      to: undefined,
      frequency: 'PER_MONTH',
      currency: 'EUR',
      isNotSpecified: false,
    },
    jobType: '',
    contractTypes: [],
  })
  const [profilePicture, setProfilePicture] = useState<any>([])
  const [cv, setCv] = useState<any>([])
  const [isDefaultDatasLoad, setIsDefaultDatasLoad] = useState(false)
  const { user, setUser } = useContext(UserContext)
  const [isChange, setIsChange] = useState<boolean>(false)
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isChange)
  const [isPublishedProfile, setIsPublishedProfile] = useState(undefined)

  useEffect(() => {
    if (user.candidateInformations) {
      setCandidateInformations(user.candidateInformations)
      const defaultProfilePicture = user.candidateInformations?.profilePicture
      if (defaultProfilePicture) {
        setProfilePicture([{ base64: defaultProfilePicture, name: 'profilePicture.png' }])
      }
      const defaultCv = user.candidateInformations?.cv
      if (defaultCv) {
        setCv([{ base64: defaultCv, name: 'cv.pdf' }])
      }
    }
  }, [user])

  useEffect(() => {
    if (!isDefaultDatasLoad && user._id) {
      setIsDefaultDatasLoad(true)
    }
  }, [candidateInformations, user])

  const onObjectChange = (attr1: string, attr2: string, value: string) => {
    if (!isChange) {
      setIsChange(true)
    }
    setCandidateInformations({
      ...candidateInformations,
      [attr1]: { ...candidateInformations[attr1], [attr2]: value },
    })
  }

  const handleChange = (attr, value) => {
    if (!isChange) {
      setIsChange(true)
    }
    setCandidateInformations({ ...candidateInformations, [attr]: value })
  }

  const handleSave = () => {
    if (setIsLoading) {
      setIsLoading(true)
    }
    userService
      .update({
        isPublished: isPublishedProfile !== undefined ? isPublishedProfile : user.isPublished,
        candidateInformations: {
          ...candidateInformations,
          profilePicture: profilePicture[0]?.base64 || candidateInformations.profilePicture || '',
          cv: cv[0]?.base64 || candidateInformations.cv || '',
          email: candidateInformations.email || user.email,
        },
      })
      .then((res) => {
        if (setIsLoading) {
          setIsLoading(false)
        }
        if (res.status === 200) {
          setIsChange(false)
          message.success('Informations sauvegardées avec succés !')
          userService.me().then((res) => setUser(res))
        }
      })
  }

  return isDefaultDatasLoad ? (
    <Form
      responsive="width: 95vw; max-width: 850px;"
      width="850px"
      margin="50px 0 0 0"
      layout="vertical"
      onFinish={handleSave}
      isStyled
      id="candidat-informations-form"
      initialValues={{
        name: candidateInformations.name,
        email: candidateInformations.email || user.email,
      }}
    >
      <NavigationBlocker
        // @ts-ignore
        showDialog={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
        saveCallback={handleSave}
      />
      <Title margin="0 0 20px 0" color="secondary">
        Mes informations candidat
      </Title>
      {user?._id && (
        <IsPublishContainer>
          <Checkbox
            defaultChecked={
              isPublishedProfile !== undefined ? isPublishedProfile : !user.isPublished
            }
            onChange={(isChecked) =>
              setIsPublishedProfile(
                isPublishedProfile !== undefined ? isPublishedProfile : !user.isPublished,
              )
            }
          />
          <Text margin="0 0 0 10px">Je ne souhaite pas rendre mon profil public</Text>
        </IsPublishContainer>
      )}
      <Row wrap={true} width="100%" justifyContent="center">
        <Column style={{ minWidth: '280px' }} margin="5%" width="40%">
          <Form.Item
            style={{ width: '100%' }}
            name="name"
            onChange={(e) => handleChange('name', e.target.value)}
            label="Nom et prénom"
            rules={[{ required: true, message: 'Veuillez renseigner votre nom ou/et prénom' }]}
          >
            <Input maxLength={35} width="100%" />
          </Form.Item>

          <Text margin="0px 0 10px 0">Type de poste recherché</Text>
          <Radio.Group
            value={candidateInformations.jobType}
            onChange={(e) => handleChange('jobType', e.target.value)}
          >
            <Radio value={'AA'}>AA (auxiliaire ambulancier)</Radio>
            <Radio style={{ marginLeft: '0' }} value={'DEA'}>
              DEA (ambulancier diplômé d’état)
            </Radio>
          </Radio.Group>

          <Text margin="20px 0 10px 0" size="14px" weight="bold">
            Zones géographique <br />
            <span style={{ fontWeight: '400' }}>(où êtes vous prêt à intervenir)</span>
          </Text>

          <Form.Item
            style={{ width: '100%' }}
            name="Pays"
            label="Pays"
            value={candidateInformations.localisations?.countrys}
            rules={[
              {
                required: !candidateInformations.localisations?.countrys?.length,
                message: 'Veuillez renseigner votre pays',
              },
            ]}
          >
            <CountrysSelect
              defaultValue={candidateInformations.localisations?.countrys}
              isMultiple={true}
              setSelectedCountry={(value) => onObjectChange('localisations', 'countrys', value)}
            />
          </Form.Item>
          <Form.Item style={{ width: '100%' }} name="Région" label="Région">
            <RegionSelect
              defaultValue={candidateInformations.localisations?.regions}
              isMultiple={true}
              setSelectedRegion={(value) => onObjectChange('localisations', 'regions', value)}
            />
          </Form.Item>
          <Form.Item style={{ width: '100%' }} name="Départements" label="Départements">
            <DepartmentsSelect
              defaultValue={candidateInformations.localisations?.departments}
              isMultiple={true}
              setSelectedDepartments={(value) =>
                onObjectChange('localisations', 'departments', value)
              }
            />
          </Form.Item>

          <Form.Item
            style={{ width: '100%', marginTop: '15px' }}
            name="experienceYears"
            onChange={(e) => handleChange('experienceYears', e.target.value)}
            label="Année(s) d'expérience"
          >
            <InputNumber
              type="number"
              //@ts-ignore
              min={0}
              //@ts-ignore
              max={65}
              defaultValue={candidateInformations.experienceYears}
              width="100%"
            />
          </Form.Item>
          <Form.Item
            style={{ width: '100%' }}
            name="school"
            onChange={(e) => handleChange('school', e.target.value)}
            label="École"
          >
            <Input maxLength={100} defaultValue={candidateInformations.school} width="100%" />
          </Form.Item>
          <Text margin="0px 0 10px 0">Type de contrat recherché</Text>
          <Checkbox.Group
            value={candidateInformations.contractTypes}
            onChange={(value) => handleChange('contractTypes', value)}
          >
            <Checkbox value={'CDI'}>CDI</Checkbox>
            <Checkbox value={'CDD'}>CDD</Checkbox>
            <Checkbox value={'Interim'}>Interim</Checkbox>
          </Checkbox.Group>
        </Column>
        <Column style={{ minWidth: '280px' }} margin="5%" width="40%">
          <Form.Item
            style={{ width: '100%' }}
            name="email"
            onChange={(e) => handleChange('email', e.target.value)}
            label="Adresse e-mail"
            rules={[
              {
                required: true,
                message: 'Veuillez renseigner une adresse e-mail',
              },
            ]}
          >
            <Input maxLength={100} width="100%" />
          </Form.Item>
          <Form.Item
            style={{ width: '100%' }}
            name="phone"
            onChange={(e) => handleChange('phone', e.target.value)}
            label="Numéro de téléphone"
          >
            <Input maxLength={18} defaultValue={candidateInformations.phone} width="100%" />
          </Form.Item>
          <Text margin="10px 0 10px 0" weight="bold">
            Photo de profil
          </Text>
          <UploadImage fileList={profilePicture} setFileList={setProfilePicture} maxImages={1} />
          <Text margin="10px 0 10px 0" weight="bold">
            Votre CV (pdf)
          </Text>
          <UploadImage type=".pdf" fileList={cv} setFileList={setCv} maxImages={1} />
          <Input.Group style={{ alignItems: 'center', display: 'flex' }} compact>
            <SalaryInput
              salary={candidateInformations.desiredSalary}
              setSalary={(value) =>
                setCandidateInformations({ ...candidateInformations, desiredSalary: value })
              }
              title="Salaire souhaité"
            />
          </Input.Group>
        </Column>
        <Column alignItems="center">
          <Button.Primary type="primary" htmlType="submit" weight="bold" fontSize="18px">
            Enregistrer mes information
          </Button.Primary>
          <Link
            style={{ marginTop: '15px', fontSize: '16px', textDecoration: 'underline' }}
            to={`${routes.candidateRender.path}?id=${user._id}`}
          >
            Voir le rendu de mon profil
          </Link>
        </Column>
      </Row>
    </Form>
  ) : (
    <></>
  )
}

export default InfromationsForm
