/* eslint-disable */

import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { articles } from './articles'
import Header from '../../components/blog/BlogHeader'
import TagMenu from '../../components/blog/TagMenu'

import './style.css'
import ArticleCard from '../../components/blog/ArticleCard'
import SuggestionCard from '../../components/blog/SuggestionCard'

const Blog = () => {
  const [displayedArticles, setDisplayedArticles] = useState(articles)
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [selectedTag, setSelectedTag] = useState('')

  window.addEventListener('resize', function (event) {
    setWindowSize(window.innerWidth)
  })
  const sortArticles = articles.sort((a, b) => parseInt(a.id) - parseInt(b.id))
  const lastThreeArticles = [
    sortArticles[sortArticles.length - 1],
    sortArticles[sortArticles.length - 2],
    sortArticles[sortArticles.length - 3],
  ].filter(article => !!article)

  return (
    <div style={{ background: 'white' }} className="page blog">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Informations recherche et création d'offre d'emploi ambulancier (DEA et AA)</title>
        <link rel="canonical" href="https://ambulancier-emploi.fr/blog" />
        <meta
          name="description"
          content="Apprenez et découvrez les dernière actualités du métier d'ambulancier et de la recherche de candidats pour son entreprise"
        />
      </Helmet>

      <div style={{ paddingBottom: '150px' }} className="column center">
        <Header />
        <TagMenu
          setSelectedTag={setSelectedTag}
          selectedTag={selectedTag}
          setDisplayedArticles={setDisplayedArticles}
        />
        <div className="blog-page-content">
          <div className="blog-page-articles-container">
            {displayedArticles
              .sort((a, b) => b.id - a.id)
              .map((article) => (
                <ArticleCard
                  selectedTag={selectedTag}
                  setSelectedTag={setSelectedTag}
                  setDisplayedArticles={setDisplayedArticles}
                  article={article}
                />
              ))}
          </div>
          <div className="blog-page-suggestions-container">
            {lastThreeArticles.map((article) => (
              <SuggestionCard article={article} />
            ))}
          </div>
        </div>
      </div>

    </div>
  )
}

export default Blog
