import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { message } from 'antd'

import { Title, Row, Column, Button, Text, H1, H2, H3, H4 } from 'styles/global'
import { routes } from 'router'
import { InformationsForm } from 'components/candidateAccount'
import { Loader } from 'components'
import { useAuth } from 'hooks'
import { UserContext } from 'providers'

const CandidateAccount = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { user } = useContext(UserContext)
  const { logout } = useAuth()

  const navigate = useNavigate()

  return (
    <Column width="100%" alignItems="center">
      <Helmet>
        <title>ambulancier-emploi | votre profil candidat</title>
        <meta name="description" content="Accédez à votre profil candidat ambulancier-emploi" />
      </Helmet>
      <Loader isDisplayed={isLoading} />
      <InformationsForm setIsLoading={setIsLoading} />

      <Button.Primary margin="40px 0 0 0" onClick={logout}>
        Se déconnecter
      </Button.Primary>
    </Column>
  )
}

export default CandidateAccount
