import React, { useContext, useState } from 'react'
import { Helmet } from 'react-helmet'

import { Container, ContentContainer } from './style'
import { H1, Text, Row, Button, Table, Column } from 'styles/global'
import { Skeletons, JobOfferCard, Filter } from 'components'

import { jobOfferService } from 'services'
import { useQuery } from 'react-query'
import { parseDate } from 'utils'
import { UserContext } from 'providers'
import { message, Modal, Space } from 'antd'
import { JobOffer } from 'types'
import { theme } from 'styles'
import { routes } from 'router'
import { Link } from 'react-router-dom'

const MyOffers = () => {
  const [removedOffer, setRemovedOffer] = useState<JobOffer | undefined>(undefined)
  const { user } = useContext(UserContext)

  const {
    isLoading,
    data: jobOffers,
    isSuccess: isDatasLoad,
    refetch,
  } = useQuery(['myOffers', user._id], () =>
    jobOfferService.companyOffers({ id: user._id }).then((res) => {
      return res.data
    }),
  )

  const handleUnpublish = () => {
    if (removedOffer) {
      jobOfferService.update(removedOffer, { id: removedOffer._id }).then((res) => {
        if (res.status === 200) {
          message.success(`Offer d'emploi déplubliée avec succés`)
          setRemovedOffer(undefined)
          refetch()
        } else {
          message.error(`Une erreur est survenue, veuillez nous contacter`)
        }
      })
    }
  }

  const handlePublish = (newOffer) => {
    if (newOffer) {
      jobOfferService.update(newOffer, { id: newOffer._id }).then((res) => {
        if (res.status === 200) {
          message.success(`Offer d'emploi publiée avec succés`)
          refetch()
        } else {
          message.error(`Une erreur est survenue, veuillez nous contacter`)
        }
      })
    }
  }

  const isOfferExpired = (offer) => {
    if (offer.expiredDate) {
      return new Date(offer.expiredDate) < new Date()
    } else {
      return true
    }
  }

  const columns = [
    {
      title: 'Date de publication',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (text) => <Text>{parseDate(text)}</Text>,
      sorter: {
        compare: (a, b) => a.createdDate?.localeCompare(b.createdDate),
      },
    },
    {
      title: 'Date d’expiration',
      dataIndex: 'expiredDate',
      key: 'expiredDate',
      render: (text, record) => (
        <Text color={isOfferExpired(record) && 'red'}>{parseDate(text)}</Text>
      ),
      sorter: {
        compare: (a, b) => a.expiredDate?.localeCompare(b.expiredDate),
      },
    },
    {
      title: 'Type de contrat',
      dataIndex: 'contractType',
      key: 'contractType',
      sorter: {
        compare: (a, b) => a.contractType?.localeCompare(b.contractType),
      },
    },
    {
      title: 'Date de début',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (text) => <Text>{text}</Text>,
      sorter: {
        compare: (a, b) => a.startDate?.localeCompare(b.startDate),
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: 450,
      render: (_, record) => (
        <Space size="middle">
          {record.applicationCount > 0 ? (
            <Link to={`${routes.applicationList.path}?id=${record._id}`}>
              <Text weight="600" underline pointer color="primary">
                Candidatures ({record.applicationCount})
              </Text>
            </Link>
          ) : (
            <Text weight="400" underline color="primary">
              Candidatures (0)
            </Text>
          )}
          {!isOfferExpired(record) && (
            <>
              {record.isPublished === false ? (
                <Text
                  underline
                  pointer
                  color="primary"
                  onClick={() => handlePublish({ ...record, isPublished: true })}
                >
                  Rendre public
                </Text>
              ) : (
                <Text
                  underline
                  pointer
                  style={{ color: 'red' }}
                  onClick={() => setRemovedOffer({ ...record, isPublished: false })}
                >
                  Ne plus rendre public
                </Text>
              )}
            </>
          )}
          <Link to={`${routes.editOffer.path}?editedOfferId=${record._id}`}>
            <Text underline pointer color="primary">
              Éditer
            </Text>
          </Link>

          <Link to={`${routes.jobOffer.path}?id=${record._id}`}>
            <Text underline pointer color="primary">
              Voir
            </Text>
          </Link>
        </Space>
      ),
    },
  ]

  return (
    <Container>
      <Helmet>
        <title>Retrouver ses offres</title>
        <meta name="description" content="Retrouver ses offres d'emploi ambulancier (DEA et AA)" />
      </Helmet>

      <Modal visible={!!removedOffer} onCancel={() => setRemovedOffer(undefined)} footer={[]}>
        <Column alignItems="center" width="100%">
          <Text size="18px">Êtes vous sûr de vouloir dépublier l'annonce ?</Text>
          <Text size="13px">
            (vous pourrez la rendre visible de nouveau jusqu&apos;à son expiration)
          </Text>
          <Button.Primary margin="20px 0 0 0" onClick={handleUnpublish}>
            Ne plus rendre public cette annonce
          </Button.Primary>
        </Column>
      </Modal>
      <H1
        size="30px"
        style={{ marginTop: '30px', maxWidth: '90vw', marginBottom: '30px' }}
        weight="bold"
        position="center"
        color="secondary"
      >
        Mes offres d&apos;emploi
      </H1>
      <ContentContainer responsive="width: 100%;" width="80%">
        <Link style={{ marginBottom: '10px' }} to={routes.createOffer.path}>
          <Button.Primary margin="10px 0 0 20px">Créer une nouvelle offre</Button.Primary>
        </Link>
        {isLoading ? (
          <Skeletons />
        ) : (
          <Table
            pagination={false}
            scroll={{ x: 1250, y: 600 }}
            columns={columns}
            dataSource={jobOffers}
          />
        )}
      </ContentContainer>
    </Container>
  )
}

export default MyOffers
