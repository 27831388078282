import { RoutesType } from 'types'
import { ACCOUNT_TYPES } from 'constants/index'

const routes: RoutesType = {
  home: {
    path: '/',
    name: 'home',
    access: '*',
    haveMenu: true,
  },
  adminDashboard: {
    path: '/admin',
    name: 'adminDashboard',
    access: ACCOUNT_TYPES.ADMIN,
    haveMenu: true,
  },
  candidateAccount: {
    path: '/my-candidate-account',
    name: 'candidateAccount',
    access: ACCOUNT_TYPES.CANDIDATE,
    haveMenu: true,
  },
  candidateRender: {
    path: '/candidate',
    name: 'candidateRender',
    access: '*',
    haveMenu: true,
  },
  companyProfile: {
    path: '/profil-entreprise',
    name: 'companyProfile',
    access: '*',
    haveMenu: true,
  },
  contact: {
    path: '/contact',
    name: 'contact',
    access: '*',
    haveMenu: true,
  },
  jobOffer: {
    path: '/offre-emploi-ambulancier',
    name: 'jobOffer',
    access: '*',
    haveMenu: true,
  },
  myApplications: {
    path: '/mes-candidatures',
    name: 'myApplications',
    access: ACCOUNT_TYPES.CANDIDATE,
    haveMenu: true,
  },
  applicationList: {
    path: '/candidatures',
    name: 'applications',
    access: ACCOUNT_TYPES.COMPANY,
    haveMenu: true,
  },
  companyAccount: {
    path: '/my-company-account',
    name: 'companyAccount',
    access: ACCOUNT_TYPES.COMPANY,
    haveMenu: true,
  },
  myOffers: {
    path: '/mes-offres',
    name: 'myOffers',
    access: ACCOUNT_TYPES.COMPANY,
    haveMenu: true,
  },
  createOffer: {
    path: '/creer-une-offre-emploi',
    name: 'createOffer',
    access: ACCOUNT_TYPES.COMPANY,
    haveMenu: true,
  },
  editOffer: {
    path: '/editer-une-offre-emploi',
    name: 'editOffer',
    access: ACCOUNT_TYPES.COMPANY,
    haveMenu: true,
  },
  candidateList: {
    path: '/liste-des-profils-ambulanciers',
    name: 'candidateList',
    access: '*',
    haveMenu: true,
  },
  viewOffers: {
    path: '/decouvrir-offres-emploi',
    name: 'viewOffers',
    access: '*',
    haveMenu: true,
  },
  pricing: {
    path: '/nos-tarifs',
    name: 'plans',
    access: '*',
    haveMenu: true,
  },
  CGV: {
    path: '/conditions-generales-de-vente',
    name: 'cgv',
    access: '*',
    haveMenu: true,
  },
  CGU: {
    path: '/conditions-generales-utilisation',
    name: 'cgu',
    access: '*',
    haveMenu: true,
  },
  blogHome: {
    path: '/blog',
    name: 'blog',
    access: '*',
    haveMenu: true,
  },

  // BLOG PAGES

  article1: {
    path: '/exemple-url-article-1',
    name: 'article1',
    access: '*',
    haveMenu: true,
  }
}

export default routes
