import React, { ReactElement, useState, Dispatch, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Input, InputPassword, Button, Form, Title, Text, Column, Row } from 'styles/global'
import { useAuth } from 'hooks'
import { Loader, UploadImage } from 'components'
import { Checkbox, message, Radio, Space } from 'antd'
import { authService } from 'services'
import { ACCOUNT_TYPES } from 'constants/index'
import { routes } from 'router'

type Credential = {
  email: string
  password: string
  repeatPassword?: string
  type?: string
}

function LoginForm({
  setIsResetPasswordDisplayed,
  redirection,
  setIsDisplayed,
  isRegistration,
  defaultEmail,
}: {
  setIsResetPasswordDisplayed: Dispatch<SetStateAction<boolean>>
  redirection: string | 'default'
  setIsDisplayed: Dispatch<SetStateAction<string>>
  isRegistration?: boolean
  defaultEmail?: string
}): ReactElement {
  const candidateTypeRedirections = [
    routes.candidateAccount.path,
    routes.jobOffer.path,
    routes.companyProfile.path,
  ]
  const isDefaultCandidateType = candidateTypeRedirections.some((redirect) =>
    redirection.includes(redirect),
  )

  const companyTypeRedirections = [
    routes.companyAccount.path,
    routes.pricing.path,
    routes.createOffer.path,
  ]
  const isDefaultCompanyType = companyTypeRedirections.some((redirect) =>
    redirection.includes(redirect),
  )

  const { t } = useTranslation()
  const [credentials, setCredentials] = useState<Credential>({
    email: defaultEmail || '',
    password: '',
    repeatPassword: '',
    type: isDefaultCandidateType
      ? ACCOUNT_TYPES.CANDIDATE
      : isDefaultCompanyType
      ? ACCOUNT_TYPES.COMPANY
      : '',
  })
  const [isLogin, setIsLogin] = useState<boolean>(isRegistration ? false : true)
  const [isRegistrationLoading, setIsRegistrationLoading] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [cv, setCv] = useState<Array<any>>([])
  const [isPublished, setIsPublished] = useState(true)

  const { login, isLoading } = useAuth()
  const navigate = useNavigate()

  const handleRegistration = () => {
    if (!cv.length && credentials.type === ACCOUNT_TYPES.CANDIDATE) {
      message.error('Veuillez ajouter votre CV')
    } else if (!(credentials.password.length >= 8)) {
      message.error('Votre mot de passe doit faire au minimum 8 caractères')
    } else if (credentials.repeatPassword !== credentials.password) {
      message.error('Le mot de passe répété de correspond pas')
    } else {
      setIsRegistrationLoading(true)
      authService
        .registration({
          email: credentials.email,
          password: credentials.password,
          type: credentials.type || '',
          isPublished: credentials.type === ACCOUNT_TYPES.COMPANY ? true : isPublished,
          candidateInformations: {
            name: name,
          },
          cv: cv[0]?.base64,
        })
        .then((res) => {
          if (res.status === 201) {
            login({
              email: credentials.email,
              password: credentials.password,
              redirection: undefined,
              callback: (res) => {
                let redirect = redirection
                if (!redirect || redirect === 'default') {
                  redirect =
                    credentials.type === ACCOUNT_TYPES.CANDIDATE
                      ? routes.candidateAccount.path
                      : routes.myOffers.path
                }
                navigate(redirect)
                setIsDisplayed('')
              },
            })
            setIsRegistrationLoading(false)
          } else {
            message.warning('Veuillez essayer avec une autre adresse email ou nous contacter')
            setIsRegistrationLoading(false)
          }
        })
    }
  }

  const handleLogin = () => {
    login({
      email: credentials.email,
      password: credentials.password,
      redirection: undefined,
      callback: (data) => {
        let redirect = redirection
        if (!redirect || redirect === 'default') {
          redirect =
            data.user?.type === ACCOUNT_TYPES.COMPANY
              ? routes.myOffers.path
              : routes.viewOffers.path
        }
        navigate(redirect)
        setIsDisplayed('')
      },
    })
  }

  return (
    <Column alignItems="center">
      <Loader isDisplayed={isLoading || isRegistrationLoading} />
      <Title margin="40px">{isLogin ? 'Connexion' : 'Inscription'}</Title>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={() => (isLogin ? handleLogin() : handleRegistration())}
        autoComplete="off"
        layout="vertical"
        style={{ alignItems: 'flex-start' }}
      >
        {!isLogin && (
          <>
            <Text size="16px" margin="0 0 10px 0" weight="bold">
              Choisir votre type de compte
            </Text>
            <Form.Item
              label=""
              name="Choisir votre type de compte"
              rules={[
                { required: !credentials.type, message: 'Veuillez choisir votre type de compte' },
              ]}
              value={credentials.type}
              defaultValue={credentials.type}
            >
              <Radio.Group
                onChange={(e) => setCredentials({ ...credentials, type: e.target.value })}
                value={credentials.type}
                defaultValue={credentials.type}
              >
                <Space direction="vertical">
                  <Radio value={'company'}>
                    Je suis une <span style={{ fontWeight: 'bold' }}>entreprise</span>{' '}
                  </Radio>
                  <Radio value={'candidate'}>
                    Je suis un <span style={{ fontWeight: 'bold' }}>candidat</span>{' '}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <div style={{ height: '10px' }} />
          </>
        )}
        <Form.Item
          label={'Adresse email'}
          name={'email'}
          rules={[
            {
              type: 'email',
              required: true,
              message: 'Veuillez renseigner votre adresse email',
            },
          ]}
          defaultValue={credentials.email}
          onChange={(e) => setCredentials({ ...credentials, email: e.target.value })}
        >
          <Input defaultValue={credentials.email} />
        </Form.Item>

        <Form.Item
          label="Mot de passe"
          name="Mot de passe"
          rules={[{ required: true, message: 'Veuillez renseigner un mot de passe' }]}
          onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
        >
          <InputPassword />
        </Form.Item>

        {!isLogin && (
          <>
            <Form.Item
              label="Répéter le mot de passe"
              name="Répéter le mot de passe"
              rules={[{ required: true, message: 'Veuillez répéter le mot de passe' }]}
              onChange={(e) => setCredentials({ ...credentials, repeatPassword: e.target.value })}
            >
              <InputPassword />
            </Form.Item>

            {credentials.type === ACCOUNT_TYPES.CANDIDATE && (
              <>
                <Text margin="10px 0 10px 0" weight="bold">
                  Votre CV (pdf)
                </Text>
                <Form.Item
                  label=""
                  name="cv"
                  rules={[{ required: !cv.length, message: 'Veuillez renseigner votre CV' }]}
                >
                  <UploadImage type=".pdf" fileList={cv} setFileList={setCv} maxImages={1} />
                </Form.Item>
                <div style={{ height: '10px' }} />
                <Form.Item
                  label="Votre nom et prénom"
                  name="name"
                  rules={[{ required: true, message: 'Veuillez renseigner votre nom et prénom' }]}
                  onChange={(e) => setName(e.target.value)}
                >
                  <Input />
                </Form.Item>
                <Row>
                  <Checkbox onChange={(isChecked) => setIsPublished(!isChecked)} />
                  <Text margin="0 0 0 10px">Je ne souhaite pas rendre mon profil public</Text>
                </Row>
              </>
            )}
          </>
        )}

        {isLogin && (
          <Text
            onClick={() => setIsResetPasswordDisplayed(true)}
            margin="-15px 0 5px"
            position="start"
            color="secondary"
            pointer
            width="100%"
            style={{ width: '100%' }}
          >
            {t('auth.forgotPassword')}
          </Text>
        )}
        <Form.Item className="center">
          <Button.Primary width="200px" margin="20px 0 0 0" type="primary" htmlType="submit">
            {isLogin ? t('auth.connect') : `S'inscrire`}
          </Button.Primary>
        </Form.Item>
      </Form>
      {!isLogin ? (
        <Row>
          <Text size="16px">Déja un compte ?</Text>
          <Text
            size="16px"
            margin="0 0 0 8px"
            color="secondary"
            pointer
            weight="bold"
            onClick={() => setIsLogin(true)}
          >
            Se connecter
          </Text>
        </Row>
      ) : (
        <Row>
          <Text size="16px">Pas de compte ?</Text>
          <Text
            size="16px"
            margin="0 0 0 8px"
            color="secondary"
            pointer
            weight="bold"
            onClick={() => setIsLogin(false)}
          >
            S'inscrire
          </Text>
        </Row>
      )}
    </Column>
  )
}

export default LoginForm
