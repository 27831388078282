import { styled, theme, mediaQuery } from 'styles'
import { Row, Text } from 'styles/global'

export const HeaderContainer = styled(Row)`
  width: 100vw;
  height: 65px;
  background-color: white;
  padding-left: 120px;
  padding-right: 120px;
  align-items: center;
  justify-content: center;
  ${mediaQuery.mobile} {
    padding-left: 5vw;
    padding-right: 5vw;
    height: 50px;
    justify-content: space-between;
  }
`

export const MenuItem = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  color: ${theme.color.black};
  ${({ isActive }: { isActive: boolean }) =>
    isActive &&
    `
    font-weight: 500;
    border-bottom: 1px solid ${theme.color.black};
    cursor: initial;
    `};
`

export const Menu = styled(Row)`
  width: 100vw;
  background: white;
  align-items: center;
  height: 60px;
  padding-left: 14vw;
  padding-right: 14vw;
`

export const BurgerMenuContainer = styled(Row)`
  background: white;
  border-radius: 8px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 7px 20px;
`

export const BurgerMenuIcon = styled.img`
  height: 35px;
  width: auto;
  margin-right: 10px;
`

export const Logo = styled.img`
  height: 40px;
  width: auto;
  position: basolute;
  left: 3vw;
  ${mediaQuery.mobile} {
    height: 35px;
    width: auto;
    position: relative;
    left: auto;
  }
`

export const ProfilPicture = styled.img`
  height: 25px;
  width: 25px;
  max-width: 40px;
  border-radius: 50%;
  margin-left: -20px;
  margin-right: 20px;
`
export const MyProfilContainer = styled(Row)`
  width: 200px;
  margin-left 30px;
  align-items: center;
  justify-content: center;
  border: ${theme.color.greyBlack} 1px solid;
  border-radius: 10px;
  cursor: pointer;
  height: 45px;

  .ant-dropdown-trigger.ant-dropdown-open{
    height: 87%;
    width: 100%;
  }

  .ant-dropdown-trigger{
    width: 100%;
    height: 100%;
  }
`
