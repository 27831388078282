import React, { useState } from 'react'

import { H3, Row } from 'styles/global'

import { jobOfferService } from 'services'
import { useQuery } from 'react-query'
import { JobOffer } from 'types'
import { FilterParams } from 'components/GlobalComponents/filter'
import JobOfferCardHome from './JobOfferCard'
import { Container } from './style'
import { Button } from 'styles/global'

import { routes } from 'router'
import { Link } from 'react-router-dom'
import { authUtils } from 'utils'
import { ACCOUNT_TYPES } from 'constants/index'
import Login from 'components/login'

const HomeJobOfferList = () => {
  const [displayedJobOffers, setDisplayedJobOffers] = useState<JobOffer[]>([])
  const [isLoginFormDisplayed, setIsLoginFormDisplayed] = useState<string>('')

  const currentPage = 1

  const {} = useQuery(['jobOffers-home'], () =>
    jobOfferService.find({ page: currentPage }).then((res) => {
      setDisplayedJobOffers([...displayedJobOffers, ...res.data].splice(0, 8))
      return [...displayedJobOffers, ...res.data]
    }),
  )

  const checkLogin = (e, path) => {
    if (!authUtils.isAuth()) {
      e.preventDefault()
      e.stopPropagation()
      setIsLoginFormDisplayed(path)
    }
  }

  return (
    <Container>
      {isLoginFormDisplayed && (
        <Login isRegistration={true} isDisplayed={isLoginFormDisplayed} setIsDisplayed={setIsLoginFormDisplayed} />
      )}
      <H3
        size="40px"
        style={{ marginTop: '35px', maxWidth: '90vw', marginBottom: '0px' }}
        weight="300"
      >
        Les dernières offres d'emploi <b>Ambulancier</b> (DEA et AA)
      </H3>
      <Row justifyContent="center" wrap>
        {displayedJobOffers?.map((jobOffer, index) => (
          <JobOfferCardHome key={`job-offer-home-${index}`} jobOffer={jobOffer} />
        ))}
      </Row>
      <Row width="100%" justifyContent="center" wrap>
        {authUtils.currentRole() !== ACCOUNT_TYPES.CANDIDATE && (
          <Link
            onClick={(e) => checkLogin(e, routes.createOffer.path)}
            style={{ margin: '20px' }}
            to={routes.createOffer.path}
          >
            <Button.Third style={{ fontSize: '20px' }}>Publier une offre</Button.Third>
          </Link>
        )}
        <Link style={{ margin: '20px' }} to={routes.viewOffers.path}>
          <Button.Third style={{ fontSize: '20px' }}>Voir toutes les offres</Button.Third>
        </Link>
      </Row>
    </Container>
  )
}

export default HomeJobOfferList
